var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"agenda"},[(_vm.agendaLaunched)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"header"},[_vm._v("Agenda")]),_c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"filters"},[_c('dropdown',{ref:"filter0",staticClass:"filter",attrs:{"items":['All', 'Notice', 'Voting', 'Waiting Exec', 'Executed', 'Ended'],"hint":'Group',"button-type":'a',"selector-type":'a'},on:{"on-selected":function($event){return _vm.filter($event, 'status')}}}),_c('dropdown',{ref:"filter1",staticClass:"filter",attrs:{"items":['All', 'Pending', 'Accept', 'Reject', 'Dismiss'],"hint":'Status',"button-type":'a',"selector-type":'a'},on:{"on-selected":function($event){return _vm.filter($event, 'result')}}}),_c('dropdown',{ref:"filter2",staticClass:"filter",attrs:{"items":['All', 'Executed', 'Not Executed'],"hint":'Function',"button-type":'a',"selector-type":'a'},on:{"on-selected":function($event){return _vm.filter($event, 'executed')}}}),(_vm.isCandidate)?_c('dropdown',{ref:"filter3",staticClass:"filter",attrs:{"items":['All', 'Yes', 'No', 'Abstain', 'Not Voted'],"hint":'Voted',"button-type":'a',"selector-type":'a'},on:{"on-selected":function($event){return _vm.filter($event, 'voted')}}}):_vm._e(),(_vm.account )?_c('dropdown',{ref:"filter4",staticClass:"filter",attrs:{"items":['All', 'Mine'],"hint":'Remaining',"button-type":'a',"selector-type":'a'},on:{"on-selected":function($event){return _vm.filter($event, 'proposal')}}}):_vm._e()],1)]),_c('div',{staticClass:"wrapper",style:([
           _vm.$mq === 'desktop' || _vm.$mq === 'tablet' ? { 'flex-direction': 'row' } : { 'flex-direction': 'column' } ])},[_c('div',{staticClass:"agenda-container"},[_vm._l((_vm.agendasFiltered.slice(0, 5)),function(agenda){return _c('card-agenda',{key:agenda.agendaid,attrs:{"agenda":agenda}})}),(!_vm.showAllAgendas && _vm.agendasFiltered.length > 5)?_c('hide-button',{staticClass:"hide-btn",attrs:{"name":("View more agendas (" + (_vm.agendasFiltered.slice(5, _vm.agendasFiltered.length).length) + ")"),"type":'hide'},on:{"on-clicked":function($event){_vm.showAllAgendas = true;}}}):_vm._e(),(_vm.showAllAgendas)?_c('div',_vm._l((_vm.agendasFiltered.slice(5, _vm.agendasFiltered.length)),function(agenda){return _c('card-agenda',{key:agenda.agendaid,attrs:{"agenda":agenda}})}),1):_vm._e()],2),_c('div',{staticClass:"card-container",style:([
             _vm.$mq === 'desktop' || _vm.$mq === 'tablet' ?
               {
                 'width': '378px',
                 'margin-left': '15px',
               } :
               {
                 'width': '100%',
               } ])},[(_vm.isCandidate)?_c('card-vote',{attrs:{"candidates":_vm.agendaVotesByCandidates,"clength":_vm.agendaVotesByCandidates.length}}):_vm._e(),_c('card-stats',{attrs:{"candidates":_vm.agendaVotesByCandidates,"clength":_vm.agendaVotesByCandidates.length}}),_c('card-stats-committee'),_c('card-resource')],1)])]):_c('div',[_c('loading')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }