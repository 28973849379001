const powerTonLogicFunctionsOfTypeB = [
  {
    'name': 'setInfo',
    'title': '',
    'prettyName': '',
    'disabled': false,
    'explanation': 'This function execute setInfo function to set the informations in PowerTON.',
  },
];

module.exports.powerTonLogicFunctionsOfTypeB = powerTonLogicFunctionsOfTypeB;
