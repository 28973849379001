<template>
  <div class="page-not-found">
    404 | This page could not be found
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.page-not-found {
  /* All views must have this attribute.*/
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
